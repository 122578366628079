import { useEffect, useMemo } from 'react'
import { Button } from 'src/components/primitives/button'
import { OrgJobsDropdown } from '../org-jobs-dropdown'
import RouteBuilder from 'src/libs/route-builder'
import { useParams } from 'react-router-dom'
import { Icon, Icons } from 'src/components/primitives/icon'
import { useJobSequenceQuery } from 'src/hooks/queries/use-job-sequence'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { APPLICANT_STAGES, CandidateJobStage, IN_OUTREACH_STEPS } from 'src/libs/api/backend/candidate_jobs'
import { isNil } from 'lodash'
import { When } from '../when'
import { useSession } from 'src/hooks/use-session'
import { Flex } from 'src/components/primitives/flex'
import { Heading } from 'src/components/primitives/typography'
import * as S from './navigation.styled'
import { useQueryParams } from 'src/hooks/use-query-params'
import { generateUrlWithParams } from 'src/utils/format-url'
import { FeatureFlags } from 'src/libs/api/backend/session'
import { useListJobSearchRefinementsQuery } from 'src/hooks/queries/use-job-search-refinements'
import queryClient from 'src/hooks/query-client'
import { queryKeys } from 'src/libs/query-keys'
import { EVENT_TYPE } from 'src/libs/api/backend/websockets'
import { SequenceStepGenerationState, SequenceStepType } from 'src/libs/api/backend/sequences'
import { isSequenceStepsEmpty } from 'src/libs/sequence'
import { useJobStatsQuery } from 'src/hooks/queries/use-job-stats'
import { useJobQuery } from 'src/hooks/queries/use-job'
import { JobSearchRefinementType } from 'src/libs/api/backend/jobs'
import type { Job } from 'src/libs/api/backend/jobs'
import { useLocalStorage } from 'usehooks-ts'
import { LocalStorageKey } from 'src/constants'
import { FeedbackType } from 'src/libs/api/backend/feedback'
import { NavigationItem } from './navigation-item'
import type { NavItem, NavigationSubItem } from './navigation-item'
import { NavigationBottom } from './navigation-bottom'
import { SettingParams } from 'src/components/dialogs/default-settings-dialog'
import { useChannel } from 'ably/react'
import { useAtomValue, useSetAtom } from 'jotai'
import { notificationsChannelAtom } from 'src/stores/websocket-channels'
import { controlDialogAtom, DialogId, isDialogOpenAtom, openDialogAtom } from 'src/stores/dialogs'
import { useApplicantsOverviewStatusQuery } from 'src/hooks/queries/use-applicants-overview-status'
import { ProductTourStep } from 'src/libs/product-tour'

interface NavigationProps {
  isCollapsed: boolean
  onCollapseChange: (value: boolean) => void
}

export const Navigation = ({ isCollapsed, onCollapseChange }: NavigationProps): JSX.Element => {
  const { user: currentUser, featureFlags } = useSession()
  const { jobId } = useParams()
  const { data: sequence } = useJobSequenceQuery()
  const { data: jobData } = useJobQuery()
  const { data: candidateJobCounts } = useCandidateJobCountsQuery()
  const { data: applicantsOverviewStatus } = useApplicantsOverviewStatusQuery(jobId)
  const [reportFeedbackBannerClosed] = useLocalStorage(LocalStorageKey.REPORT_FEEDBACK_BANNER_CLOSED, false)
  const searchDialogIsOpen = useAtomValue(useMemo(() => isDialogOpenAtom(DialogId.SEARCH_CANDIDATES), []))
  const { setParam } = useQueryParams()
  const openDialog = useSetAtom(openDialogAtom)
  const controlDialog = useSetAtom(controlDialogAtom)
  const { data: jobSearchRefinements = [] } = useListJobSearchRefinementsQuery(jobId, {
    type: JobSearchRefinementType.SEARCH_REFINEMENT
  })
  const { data: jobStats } = useJobStatsQuery()
  const CANDIDATES_BASE_PATH = useMemo(() => `/jobs/${jobId}/candidates`, [jobId])
  const notificationsChannel = useAtomValue(notificationsChannelAtom)

  useChannel({ channelName: notificationsChannel, skip: !notificationsChannel }, (message) => {
    const { name: eventName, data } = message
    if (eventName === EVENT_TYPE.ORG_NOTIFICATION || eventName === currentUser?.id) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.notifications]
      })
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.notificationsCount]
      })
    }
    if (eventName === jobId && data?.sequenceStepStatus === SequenceStepGenerationState.COMPLETED) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.jobSequence, jobId]
      })
    }
    if (eventName === EVENT_TYPE.MANUAL_OUTREACH && data?.jobId === jobId) {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateSequenceStepMessageReviews, jobId]
      })
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.candidateJobCounts, jobId]
      })
    }
  })

  const readyToSendCount = useMemo(() => {
    if (!featureFlags?.includes(FeatureFlags.MANUAL_OUTREACH)) {
      return 0
    }
    return candidateJobCounts?.readyToSend ?? 0
  }, [candidateJobCounts?.readyToSend, featureFlags])

  const hasCandidateJobsError = useMemo(() => {
    return !isNil(candidateJobCounts?.prospectingErrors) && candidateJobCounts.prospectingErrors > 0
  }, [candidateJobCounts?.prospectingErrors])

  const isEmptySequence = isSequenceStepsEmpty(sequence)

  const outreachBadgeText = useMemo(() => {
    if (isEmptySequence) {
      return 'ENABLE'
    }

    if (sequence?.active === false) {
      return 'PAUSED'
    }

    if (jobId && jobStats && jobStats[jobId]?.hasDeactivatedSenders) {
      return 'PAUSED'
    }

    return undefined
  }, [sequence?.active, jobId, jobStats, isEmptySequence])

  const aiAutomationsCount = useMemo((): { activated: number, total: number } => {
    let count = 0
    for (const key in jobData) {
      if (key.startsWith('automate') && !key.includes('Daily') && jobData[key as keyof Job]) {
        count++
      }
    }
    if (sequence?.active) {
      count++
    }
    if (!isNil(jobData?.autoExportCandidate)) {
      count++
    }
    return {
      activated: count,
      total: featureFlags?.includes(FeatureFlags.ATS_INTEGRATION) ? 6 : 5
    }
  }, [jobData, sequence?.active, featureFlags])

  const readyToSendEnabled = useMemo(() => {
    return sequence?.sequenceSteps?.some((step) => step.type === SequenceStepType.MANUAL_EMAIL)
  }, [sequence?.sequenceSteps])

  const sequenceSteps: NavigationSubItem[] = useMemo(() => {
    let steps: NavigationSubItem[] = []
    if (hasCandidateJobsError) {
      steps.push(
        {
          id: 'sequence-errors',
          title: 'Errors',
          badgeVariant: 'negative',
          icon: Icons.alertTriangle,
          iconColor: 'fgPrimary',
          count: candidateJobCounts?.prospectingErrors,
          href: '/errors'
        }
      )
    }
    if (readyToSendEnabled) {
      steps.push(
        {
          id: 'ready-to-send',
          title: 'Ready To Send',
          href: '/ready-to-send',
          badgeVariant: readyToSendCount > 0 ? 'tint' : 'bgSecondary',
          icon: Icons.send,
          iconColor: 'fgPrimary',
          count: readyToSendCount,
          featureFlag: FeatureFlags.MANUAL_OUTREACH
        }
      )
    }

    // steps.push({
    //   id: 'edit-outreach',
    //   title: 'Edit Outreach',
    //   href: `${RouteBuilder.build('SETTINGS_JOB')}/${jobId}/email-sequence`,
    //   icon: Icons.settings2,
    //   iconColor: 'fgPrimary'
    // })

    steps = steps.concat([
      {
        id: 'edit-outreach',
        title: 'Edit Outreach',
        href: RouteBuilder.build('SETTINGS_JOB_EMAIL_SEQUENCE', { jobId: jobId ?? '' }),
        icon: Icons.slidersHorizontal2,
        iconColor: 'fgPrimary',
        includeBasePath: false
      }
    ])

    steps = steps.concat([
      {
        id: 'outreach-status',
        title: 'Status',
        type: 'separator' as const
      },
      {
        id: 'all-contacts',
        title: 'All Contacts',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.ALL_CONTACTS }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.ALL_CONTACTS]
      },
      {
        id: 'in-progress',
        title: 'In Progress',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.IN_PROGRESS }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.IN_PROGRESS]
      },
      {
        id: 'not-yet-contacted',
        title: 'Not Yet Contacted',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.NOT_YET_CONTACTED }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.NOT_YET_CONTACTED]
      },
      {
        id: 'messaged',
        title: 'Messaged',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.MESSAGED }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.MESSAGED]
      },
      {
        id: 'opened',
        title: 'Opened',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.OPENED }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.OPENED]
      },
      {
        id: 'replied',
        title: 'Replied',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.REPLIED }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.REPLIED]
      },
      {
        id: 'scheduled',
        title: 'Scheduled',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.SCHEDULED }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.SCHEDULED]
      },
      {
        id: 'no-response',
        title: 'No Response',
        searchParams: generateUrlWithParams('', { step: IN_OUTREACH_STEPS.NO_RESPONSE }),
        count: candidateJobCounts?.candidateInOutreach[IN_OUTREACH_STEPS.NO_RESPONSE]
      }
    ])

    return steps
  }, [
    candidateJobCounts?.candidateInOutreach,
    candidateJobCounts?.prospectingErrors,
    hasCandidateJobsError,
    // isEmptySequence,
    readyToSendCount,
    readyToSendEnabled,
    jobId
  ])

  const applicantsSubSteps: NavigationSubItem[] = useMemo(() => {
    if (!featureFlags?.includes(FeatureFlags.APPLICANTS)) {
      return []
    }
    return [
      {
        id: 'incoming-applicants',
        title: 'Incoming Applicants',
        searchParams: generateUrlWithParams('', { stage: APPLICANT_STAGES.INCOMING }),
        count: applicantsOverviewStatus?.applicantsCount ?? 0
      },
      {
        id: 'shortlisted',
        title: 'Shortlisted',
        searchParams: generateUrlWithParams('', { stage: APPLICANT_STAGES.SHORTLISTED }),
        count: applicantsOverviewStatus?.shortlisted ?? 0,
        icon: Icons.star
      },
      {
        id: 'approved',
        title: 'Approved',
        searchParams: generateUrlWithParams('', { stage: APPLICANT_STAGES.APPROVED }),
        count: applicantsOverviewStatus?.approved ?? 0,
        icon: Icons.checkCircle
      },
      {
        id: 'archived',
        title: 'Archived',
        searchParams: generateUrlWithParams('', { stage: APPLICANT_STAGES.ARCHIVED }),
        count: applicantsOverviewStatus?.archived ?? 0,
        icon: Icons.archive
      }
    ]
  }, [applicantsOverviewStatus?.applicantsCount, applicantsOverviewStatus?.approved, applicantsOverviewStatus?.archived, applicantsOverviewStatus?.shortlisted])

  const navigationItems: NavItem[] = useMemo(() => ([
    {
      id: 'applicants',
      title: 'Applicants',
      type: 'link',
      icon: Icons.clipboard,
      basePath: RouteBuilder.build('JOBS_APPLICANTS', { jobId: jobId ?? '' }),
      href: '/',
      // featureFlag: FeatureFlags.APPLICANTS,
      subItems: applicantsSubSteps
    },
    {
      // id: 'sourcing',
      id: ProductTourStep.MENU_SOURCING,
      title: 'Sourcing',
      type: 'link',
      icon: Icons.binoculars,
      basePath: `${CANDIDATES_BASE_PATH}/sourcing`,
      href: jobSearchRefinements.length > 0
        ? `/${jobSearchRefinements[0].id}`
        : '/search',
      count: (candidateJobCounts?.manuallyAdded ?? 0) + (candidateJobCounts?.shortlisted?.sourced ?? 0),
      subItems: [
        // {
        //   title: 'All sourced',
        //   href: '/',
        //   count: candidateJobCounts?.stages[CandidateJobStage.SOURCED]
        // },
        {
          id: 'manually-added',
          title: 'Manually Added',
          href: '/manual',
          count: candidateJobCounts?.manuallyAdded
        },
        {
          id: 'shortlisted',
          title: 'Shortlisted',
          href: '/shortlisted',
          count: candidateJobCounts?.shortlisted?.sourced ?? 0,
          icon: Icons.star
        },
        ...((!jobData?.deleted || jobSearchRefinements.length > 0)
          ? [{
              id: 'ai-sourced',
              title: 'AI Sourced',
              type: 'separator' as const
            }]
          : []),
        ...jobSearchRefinements.map((refinement, i) => ({
          id: refinement.id,
          title: refinement.title ?? `Search #${i + 1}`,
          loading: !refinement.title,
          href: `/${refinement.id}`
        })),
        ...(!jobData?.deleted
          ? [{
              id: 'new-search',
              title: 'New Pin Search',
              href: '/search',
              icon: Icons.plus
            }]
          : [])
        // {
        //   // XXX
        //   title: 'New Pin Search',
        //   href: '/search',
        //   icon: 'plus-circle'
        // }
      ]
    },
    // {
    //   title: 'Shortlisted',
    //   type: 'link',
    //   basePath: `${CANDIDATES_BASE_PATH}/shortlisted`,
    //   href: '/',
    //   icon: Icons.star,
    //   count: candidateJobCounts?.shortlisted
    //   // subItems: [
    //   //   {
    //   //     title: 'All shortlisted',
    //   //     href: '/',
    //   //     count: candidateJobCounts?.shortlisted
    //   //   }
    //   // ]
    // },
    {
      // id: 'outreach',
      id: ProductTourStep.MENU_OUTREACH,
      title: 'Outreach',
      type: 'link',
      icon: Icons.mails,
      count:
        readyToSendCount > 0
          ? readyToSendCount
          : hasCandidateJobsError
            ? candidateJobCounts?.prospectingErrors
            : candidateJobCounts?.stages[CandidateJobStage.PROSPECTING],
      expandedCount: candidateJobCounts?.stages[CandidateJobStage.PROSPECTING],
      badgeText: outreachBadgeText,
      badgeVariant: outreachBadgeText
        ? outreachBadgeText === 'ENABLE'
          ? 'warningLight'
          : 'negativeLight'
        : hasCandidateJobsError
          ? 'negative'
          : readyToSendCount > 0
            ? 'tint'
            : 'bgSecondary',
      basePath: !isSequenceStepsEmpty(sequence) ? `${CANDIDATES_BASE_PATH}/in-sequence` : undefined,
      // href: readyToSendCount > 0 ? '/ready-to-send' : hasCandidateJobsError ? '/errors' : '/',
      href: !isSequenceStepsEmpty(sequence) ? '/' : undefined,
      onClick: () => { isSequenceStepsEmpty(sequence) && openDialog({ id: DialogId.CREATE_SEQUENCE }) },
      subItems: sequenceSteps
    },
    {
      // id: 'inbox',
      id: ProductTourStep.MENU_INBOX,
      title: 'Inbox',
      type: 'link',
      basePath: RouteBuilder.build('JOBS_INBOX', { jobId: jobId ?? '' }),
      href: '/candidate_jobs',
      icon: Icons.inbox,
      count: candidateJobCounts?.inbox.totalCommunicating,
      badgeVariant: candidateJobCounts?.inbox.totalAwaitingResponse ? 'tint' : 'bgSecondary',
      subItems: [
        {
          id: 'all-in-inbox',
          title: 'All In Inbox',
          href: '/candidate_jobs',
          count: candidateJobCounts?.inbox.totalCommunicating,
          // icon: Icons.folder,
          badgeVariant: candidateJobCounts?.inbox.totalAwaitingResponse ? 'tint' : 'bgSecondary'
        },
        {
          id: 'my-communications',
          title: 'My Communications',
          searchParams: `?lastCommunicatedWithUserId=${currentUser?.id}`,
          count: candidateJobCounts?.inbox.myTotalCommunicating,
          // icon: Icons.folder,
          badgeVariant: candidateJobCounts?.inbox.myTotalAwaitingResponse ? 'tint' : 'bgSecondary'
        }
      ]
    },
    {
      id: 'separator-1',
      title: '',
      type: 'separator'
    },
    {
      id: 'all-candidates',
      title: 'All Candidates',
      basePath: RouteBuilder.build('JOBS_CANDIDATES_ALL', { jobId }),
      count: (candidateJobCounts?.total.totalWithoutDataWarehouseSourcedCount ?? 0) - (candidateJobCounts?.stages?.REJECTED ?? 0),
      icon: Icons.users,
      href: '/',
      type: 'link',
      subItems: [
        {
          id: 'all-candidates-sub',
          title: 'All Candidates',
          href: '/',
          count: (candidateJobCounts?.total.totalWithoutDataWarehouseSourcedCount ?? 0) - (candidateJobCounts?.stages?.REJECTED ?? 0)
        },
        {
          id: 'shortlisted',
          title: 'Shortlisted',
          href: '/shortlisted',
          icon: Icons.star,
          count: candidateJobCounts?.shortlisted.total
        },
        {
          id: 'archived',
          title: 'Archived',
          href: '/archived',
          icon: Icons.archive,
          count: candidateJobCounts?.stages[CandidateJobStage.REJECTED]
        }
      ]
    },
    {
      // id: 'reports',
      id: ProductTourStep.MENU_REPORTS,
      title: 'Reports',
      basePath: RouteBuilder.build('JOBS_REPORTS', { jobId: jobId ?? '' }),
      icon: Icons.pieChart,
      href: '/candidates',
      type: 'link',
      subItems: [
        {
          id: 'report-candidates',
          title: 'Candidates',
          href: '/candidates'
        },
        {
          id: 'report-communications',
          title: 'Communications',
          href: '/communications'
        },
        ...(reportFeedbackBannerClosed
          ? [{
              id: 'suggest-report',
              title: 'Suggest a report',
              icon: Icons.conciergeBell,
              onClick: () => {
                openDialog({
                  id: DialogId.SEND_FEEDBACK,
                  payload: {
                    type: FeedbackType.IDEA,
                    defaultContent: 'I have a suggestion for reports:<br>'
                  }
                })
              }
            }]
          : []
        )
      ]
    },
    {
      // id: 'settings',
      id: ProductTourStep.MENU_JOB_SETTINGS,
      title: 'Job Settings',
      icon: Icons.settings2,
      type: 'link',
      basePath: `${RouteBuilder.build('SETTINGS_JOB')}/${jobId}`,
      href: '/details',
      subItems: [
        {
          id: 'position-details',
          title: 'Position Details',
          href: '/details',
          icon: Icons.info
        },
        {
          id: 'outreach-sequence',
          title: 'Outreach Sequence',
          href: '/email-sequence',
          icon: Icons.mail
        },
        {
          id: 'company-ratings',
          title: 'Companies Ratings',
          href: '/companies-ratings',
          icon: Icons.thumbsUp,
          featureFlag: FeatureFlags.COMPANY_PREFERENCES
        },
        {
          id: 'email-preferences',
          title: 'Email Preferences',
          href: '/email-preferences',
          icon: Icons.mail
        },
        {
          id: 'calendar-preferences',
          title: 'Calendar Preferences',
          href: '/calendar-preferences',
          icon: Icons.calendar,
          featureFlag: FeatureFlags.CALENDAR
        },
        {
          id: 'user-team-settings',
          title: 'User & Team Settings',
          icon: Icons.settings,
          onClick: () => {
            setParam('settings', SettingParams.PROFILE)
            openDialog({
              id: DialogId.DEFAULT_SETTINGS
            })
          }
          // // iconColor: 'tintBg',
          // colorTheme: 'tint'
        }
      ]
    },
    {
      id: 'pin-automation',
      title: 'Automation',
      icon: Icons.pinNormal,
      type: 'link',
      count: `${aiAutomationsCount.activated} / ${aiAutomationsCount.total}`,
      basePath: RouteBuilder.build('JOBS_AUTOMATION', { jobId: jobId ?? '' }),
      href: '/',
      featureFlag: FeatureFlags.AI_AUTOMATION
    }
  ]), [CANDIDATES_BASE_PATH, aiAutomationsCount.activated, aiAutomationsCount.total, applicantsSubSteps, candidateJobCounts?.inbox.myTotalAwaitingResponse, candidateJobCounts?.inbox.myTotalCommunicating, candidateJobCounts?.inbox.totalAwaitingResponse, candidateJobCounts?.inbox.totalCommunicating, candidateJobCounts?.manuallyAdded, candidateJobCounts?.prospectingErrors, candidateJobCounts?.shortlisted?.sourced, candidateJobCounts?.shortlisted.total, candidateJobCounts?.stages, candidateJobCounts?.total.totalWithoutDataWarehouseSourcedCount, currentUser?.id, hasCandidateJobsError, jobData?.deleted, jobId, jobSearchRefinements, openDialog, outreachBadgeText, readyToSendCount, reportFeedbackBannerClosed, sequence, sequenceSteps, setParam])

  // Control search dialog with cmd+k
  useEffect(() => {
    const down = (event: KeyboardEvent): void => {
      if (event.key === 'k' && event.metaKey) {
        event.preventDefault()
        controlDialog({
          id: DialogId.SEARCH_CANDIDATES,
          newState: !searchDialogIsOpen
        })
      }
    }

    document.addEventListener('keydown', down)
    return () => {
      document.removeEventListener('keydown', down)
    }
  }, [searchDialogIsOpen, controlDialog])

  return (
    <S.Navigation data-name="Navigation" $isCollapsed={isCollapsed}>
      <S.MainContent data-name="MainContent">
        <S.Top $isCollapsed={isCollapsed}>
          <When condition={!isCollapsed}>
            <Flex $align="center" $gap={4}>
              <Icon name="pin-normal" size={18} />
              <Heading $whiteSpace="nowrap" $ellipsis>
                Pin
              </Heading>
            </Flex>
          </When>
          <Flex $gap={2} $justify={isCollapsed ? 'center' : 'flex-end'} $width="auto">
            <When condition={!isCollapsed}>
              <Button
                ariaLabel='Create new job'
                leadingIcon={Icons.plusCircle}
                $variant="ghost"
                $colorTheme="muted"
                $height={32}
                $width={32}
                onClick={() => {
                  openDialog({
                    id: DialogId.CREATE_NEW_JOB
                  })
                }}
                tooltip={{
                  text: 'Create new job',
                  position: 'bottom'
                }}
              />
              <Button
                ariaLabel="Open search modal"
                leadingIcon={Icons.search}
                $variant="ghost"
                $colorTheme="muted"
                $height={32}
                $width={32}
                onClick={() => {
                  openDialog({
                    id: DialogId.SEARCH_CANDIDATES
                  })
                }}
                tooltip={{
                  text: 'Search candidates',
                  position: 'bottom',
                  keys: ['cmd', 'k']
                }}
              />
            </When>
            <Button
              ariaLabel="Collapse navigation bar"
              leadingIcon={isCollapsed ? Icons.chevronsRight : Icons.chevronsLeft}
              $variant="ghost"
              $colorTheme="muted"
              $height={32}
              $width={32}
              onClick={() => {
                onCollapseChange(!isCollapsed)
              }}
              $borderRadius={9999}
            />
          </Flex>
        </S.Top>
        <OrgJobsDropdown isCollapsed={isCollapsed} />
        <S.NavigationItems $isCollapsed={isCollapsed}>
          {navigationItems.map((navItem) => (
            <NavigationItem key={navItem.id} navItem={navItem} isCollapsed={isCollapsed} featureFlags={featureFlags} />
          ))}
        </S.NavigationItems>
      </S.MainContent>
      <NavigationBottom isCollapsed={isCollapsed} />
    </S.Navigation>
  )
}
